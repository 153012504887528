
import Vue from 'vue';
import Button from '@/components/Button.vue';
import { StepInterface, ButtonInterface } from '@/model/';
import { wizardProvider } from '@/wizard/';
import { mapGetters } from 'vuex';
import { LayoutInterface } from '../model/layout.interface';
import { TommyClient } from '../wizard/Tommy/TommyClient';

export default Vue.extend({
  components: {
    Button,
  },
  props: {
    active: {
      type: Boolean,
      default: () => false,
    },
    stepIndex: {
      type: Number,
      default: () => false as any,
    },
    stepInput: {
      type: Object,
      default: () => false as any,
    },
  },
  data() {
    return {
      step: {} as StepInterface,
      wizardProvider: wizardProvider,
      signupReservationId: '',
      signupReservationNumber: '',
      signupReservationEmail: '',
      signupReservationPassword: '',
      signupReservationNumberError: null as any,
      signupReservationPaymentError: null as any,
      signupReservationNumberSuccess: false,
      signupReservations: [] as any[],
      signupReservationDetails: null as any,
      signupShowPayment: false,
      signupOutstandingAmount: '' as any,
      signupShowConfirmation: false,
      signupReservationLocation: 'Location ID here...',
    };
  },
  async mounted() {
    if (0 === this.stepIndex) {
      this.step = this.stepInput;
      this.step = await wizardProvider.configureStep(this.stepInput, this.choices);
      this.$store.commit('SET_STEP', this.step);
    } else {
      this.step = await wizardProvider.configureStep(this.stepInput, this.choices);
      this.$store.commit('SET_STEP', this.step);

      if (this.step && 'undefined' !== typeof this.step.skip) {
        this.$store.commit('ADD_SKIPPED_STEP', this.stepNumber);

        if (typeof {} === typeof this.step.skip) {
          this.chooseSkip({ button: { ...this.step.skip } });
        } else {
          this.chooseSkip({ button: { code: this.step.skip, label: this.step.skip } });
        }
      }
    }
  },
  methods: {
    async signupSignin() {
      this.$store.commit('SET_LOADING', true);
      const client = this.$app.helper.getClient();
      const jwt = await client.postSignupSignin(this.signupReservationEmail, this.signupReservationPassword);
      this.$store.commit('SET_ACCESS_JWT', jwt);
      this.signupReservations = await client.getMyReservations(jwt);
      this.$store.commit('SET_LOADING', false);
    },
    async signupReservationInit() {
      const client = this.$app.helper.getClient();

      this.$store.commit('SET_LOADING', true);

      client
        .checkPayment(0, this.signupReservationNumber, this.$store.getters.meta.salesChannel)
        .then((data: any) => {
          if (data.data && data.data.paid === true) {
            this.signupReservationLocation = data.data.location;

            return this.signupReservationNumberClick();
          }

          if (data.data && data.data.paid === false) {
            return client
              .pushTransaction(0, this.signupReservationNumber, this.$store.getters.meta.salesChannel)
              .then((data: any) => {
                if (!data.success && data.msg === 'Reservering is al betaald.') {
                  return this.signupReservationNumberClick();
                }

                if (data.success === true) {
                  const transactionId = data.data.transactionId;
                  const totalToPay = Number.parseFloat(data.data.amount);

                  this.signupShowPayment = true;
                  this.signupOutstandingAmount = totalToPay.toFixed(2);

                  if (!transactionId) {
                    this.signupReservationPaymentError = 'Transactie niet gevonden';
                    this.$store.commit('SET_LOADING', false);
                    return;
                  }

                  let checkAttempts: number = 0;
                  let checkInterval: any = false;

                  const handleFail = (error: any) => {
                    checkAttempts++;

                    const maxAttempts = process.env.NODE_ENV === 'production' ? 30 : 3;
                    if (checkAttempts > maxAttempts) {
                      this.signupShowPayment = false;
                      clearInterval(checkInterval);
                      alert('De betaling is mislukt, probeer het opnieuw');
                      this.$store.commit('SET_LOADING', false);
                    }
                  };

                  checkInterval = setInterval(() => {
                    client
                      .checkPayment(0, this.signupReservationNumber, this.$store.getters.meta.salesChannel)
                      .then((data: any) => {
                        if (data.data && data.data.pending === true) {
                          this.signupShowPayment = true;
                        }

                        if (typeof data.data !== 'undefined' && true === (data.data.paid || false)) {
                          this.signupShowPayment = false;
                          this.signupReservationLocation = data.data.location;

                          clearInterval(checkInterval);
                          return this.signupReservationNumberClick();
                        } else {
                          handleFail(null);
                        }
                      })
                      .catch((error: any) => handleFail(error));
                  }, 2500);
                  return;
                }

                this.signupReservationPaymentError = 'De betaling is mislukt, probeer het opnieuw';
                this.$store.commit('SET_LOADING', false);
              })
              .catch((err: any) => {
                console.error('[!]', err);
                this.signupReservationPaymentError = 'De betaling is mislukt, probeer het opnieuw';
                this.$store.commit('SET_LOADING', false);
              });
          }

          this.signupReservationPaymentError = 'Er is iets fout gegaan, probeer het opnieuw';
          this.$store.commit('SET_LOADING', false);
        })
        .catch((err: any) => {
          console.error('[!]', err);
          this.signupReservationPaymentError = 'Er is iets fout gegaan, probeer het opnieuw';
          this.$store.commit('SET_LOADING', false);
        });
    },
    async signupReservationSelect(reservation: any) {
      const client = this.$app.helper.getClient();
      this.signupReservationId = reservation.id;
      this.signupReservationNumber = reservation.reservation_number;
      this.signupReservationDetails = await client.getMyReservationDetails(
        this.$store.getters.accessJwt,
        this.signupReservationId,
      );

      const totalToPay = parseFloat(this.signupReservationDetails.total_to_pay);
      if (totalToPay > 0) {
        this.signupOutstandingAmount = totalToPay.toFixed(2);
        this.signupShowPayment = true;
      } else {
        return this.signupReservationNumberClick();
      }
    },
    async signupReservationPayOutstandingAmount() {
      const client = this.$app.helper.getClient();
      const transactionResult = await client.postSignupStartPayment(this.signupReservationId, this.signupReservationNumber);
      if (!transactionResult.success) {
        this.signupReservationPaymentError = transactionResult.msg;
        return;
      }

      const transactionId = transactionResult.data.transactionId;
      if (!transactionId) {
        this.signupReservationPaymentError = 'Transactie niet gevonden';
        return;
      }

      let checkAttempts: number = 0;
      let checkInterval: any = false;

      const handleFail = (error: any) => {
        checkAttempts++;

        const maxAttempts = process.env.NODE_ENV === 'production' ? 30 : 3;
        if (checkAttempts > maxAttempts) {
          clearInterval(checkInterval);
          alert('De betaling is mislukt, probeer het opnieuw');
        }
      };

      checkInterval = setInterval(() => {
        client
          .checkTransaction(this.signupReservationId, this.signupReservationNumber, transactionId)
          .then((data: any) => {
            if (typeof data.data !== 'undefined' && true === (data.data.paid || false)) {
              this.signupShowPayment = false;
              clearInterval(checkInterval);
              return this.signupReservationNumberClick();
            } else {
              handleFail(null);
            }
          })
          .catch((error: any) => handleFail(error));
      }, 2500);
    },
    async signupReservationNumberClick() {
      this.signupShowPayment = false;
      this.$store.commit('SET_LOADING', true);

      const client = this.$app.helper.getClient();
      const resp = await client.postSignupReservation(
        this.signupReservationNumber,
        this.meta.arrivalPrinter,
        this.$store.getters.meta.salesChannel,
      );

      if (resp.success !== true) {
        this.signupReservationNumberError = resp.msg;
        this.signupReservationNumberSuccess = false;
        this.step.layout.rows[0].columns[0].data = '';
      } else {
        this.signupReservationNumberError = null;
        this.signupReservationNumberSuccess = true;
        this.step.layout.rows[0].columns[0].data = 'signup-reservation-success-details';
      }
      this.$store.commit('SET_LOADING', false);
    },
    buttonClass(button: ButtonInterface): string[] {
      const classes: any = [];
      const options: any = button.options || {};
      (options.classes || []).forEach((cls: string) => {
        classes.push(cls);
      });

      return classes;
    },
    choose(button: ButtonInterface) {
      this.$emit('choose', {
        button: button,
        step: this.step,
      });
    },
    chooseSkip(skipData: any) {
      this.$emit('choose', skipData);
    },
    getColumnClasses(column: any, base: any) {
      let classes = !base || !base.length ? [] : base;

      if (column.classes) {
        column.classes.forEach((item: string) => {
          classes.push(item);
        });
      }

      return classes;
    },
  },
  computed: {
    options(): any {
      if (this.step && this.step.options) {
        return this.step.options;
      }

      return false;
    },
    widget(): any {
      const options: any = this.options;
      if (options) {
        return options.widget || false;
      }

      return false;
    },
    layout(): LayoutInterface {
      let layout: any;
      if (this.step && this.step.layout) {
        layout = this.step.layout;
      } else {
        layout = {
          rows: [
            {
              columns: [
                {
                  type: 'title',
                  classes: ['col-12'],
                },
              ],
            },
            {
              columns: [
                {
                  type: 'buttons',
                  classes: ['col-12'],
                },
                {
                  type: 'widget',
                  classes: ['col-12'],
                },
              ],
            },
          ],
        };
      }

      return layout;
    },
    ...mapGetters(['stepNumber', 'choices', 'meta']),
  },
});

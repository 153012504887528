import { render, staticRenderFns } from "./PetsWidget.vue?vue&type=template&id=06b45085&"
import script from "./PetsWidget.vue?vue&type=script&lang=ts&"
export * from "./PetsWidget.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports